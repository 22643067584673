.logout-link {
    cursor: pointer;
    background: #da474e!important;
    border: #da474e!important;
}

.logout-link:hover{
    background: #b92d34!important;
}

.mobile-nav-link{
    display: grid;
    gap: 1.5rem;
}

.mobile-nav{
    display: none;
}

@media (max-width: 800px) {
    /* Hide the main navigation for smaller screens */
    .mobile-nav {
      display: block;
    }

    .large-nav,
    .display-desktop{
        display: none;
    }

    .navbar-logo{
        display: none;
    }

    
}

.p-sidebar-left .p-sidebar {
    width: 100%!important;
    height: 100%;
}


.mobile-nav-link .react-bubbly-transitions__bubbly-link {
    font-size: xx-large;
  }  

  /* ///////////////////////////////////////// */
.navbar-item .p-button{
    box-shadow: none !important;
}

.p-submenu-list {
    background: #2b2a2a !important;
}

.p-tieredmenu {
    background: rgba(4, 4, 4, 0.372) !important;
    backdrop-filter: blur(30px)
}

.p-tieredmenu .p-menuitem .p-menuitem-link span,
.p-menuitem.p-menuitem-active .p-menuitem-link span{
    color: white ;
}

.p-menuitem.p-menuitem-active span{
    color: black;
}

.p-menuitem a{
    border-bottom: 1px solid rgba(128, 128, 128, 0.502) !important;
}



@media (min-width: 600px) {
    .newApplication{
        display: none !important;
    }
    .react-bubbly-transitions__bubbly-link.active{
        color: white !important;
    }
  }

  .settingsIcon button span{
    font-size: 25px !important;
  }

  .settingsIcon button{
    box-shadow: none !important;
  }

  .newAppBtn{
    background: #DB424A;
    color: white;
    width: 190px;
    text-decoration: 'none' ;
  }

  @media (max-width: 600px) {
  .navbarDiv{
    display: none !important;
  }
}

.p-overlaypanel .p-overlaypanel-close{
    top:0 !important;
    right: 0rem !important;
}

.notificationList:hover{
    background: #383e94 !important;
}

@media (max-width: 1300px) {
    .navbarDiv{
      margin-left: 0 !important;
    }
    .userText{
        display: none !important;
    }

    .navContainer{
        width: 100% !important;
        margin-left: 0 !important;
    }

    .navbarSearchInput input{
        width: auto !important;
    }
  }

  @media (max-width: 1200px) {
    .breadcrumbsLogo{
        display: none;
    }
  }


  nav{
    display: flex;
    align-items: center;
  }

 .react-bubbly-transitions__bubbly-link.active::after{
    background: white !important;
  }

  .searchItems{
    padding: 10px;
    border-bottom: 1px solid grey;
    cursor: pointer;
    color: white;
  }

  .searchItems:hover{
    background: white;
    color: black;
  }

  /* //////////////////////////// */

  .navContainer{
    box-shadow: -1px -2px 0px #ffffff40; 
    /* border-radius: 10px;  */
    background: rgba(255, 255, 255, 0.06); 
    backdrop-filter: blur(2px)
  }